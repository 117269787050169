import React from 'react';
import {
    Edit,
    TextInput,
    TextField,
    ArrayInput,
    DateInput,
    TabbedForm,
    FormTab,
    DateField,
    ReferenceArrayInput,
    LongTextInput,
    required,
    FormDataConsumer,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
} from 'react-admin';
import RichTextInput from '../field/editor/';
import MyTextField from '../field/MyTextField';
import { TargetInput } from '../field/TargetField';
import { styles, SiteIDData, findLabels, findDataFields, findRatesFields } from '../constants';
import { ZeroOneBooleanInput } from '../field/bool';
import { MyStatus, MyUser, MyClickType } from '../field/customAdsFields';
import SiteadsBanner from './Banner.js';
import { SimpleFormIterator2 } from '../field/SimpleFormIterator';
import Typography from '@material-ui/core/Typography';
import CommitButton from '../button/AdsCommit';
import DropButton from '../button/AdsDrop';

const AdTitle = ({ record }) => {
    return <span>{record ? `${record.title}` : ''}</span>;
};

const Aside = ({ record }) => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Admin Actions:</Typography>
        {record && (
            <CommitButton record={record} />
        )}
        {record && (
            <DropButton record={record} />
        )}
    </div>
)

const SiteAdHref = ({ record ={}, source }) => {
    return (
        <>
            <LongTextInput label="Hivatkozás URL (változók: $osszeg, $honap, $bevetel, $thm, $torleszto)" source={source} className="longtext-width" />
        </>
    );
};

const SiteAdHrefCall = ({ record ={}, source }) => {
    return (
        <>
            <LongTextInput label="Visszahívás URL (ha van)" source={source} />
        </>
    );
};

const SiteAdHrefList = ({ record ={}, source }) => {
    return (
        <>
            <LongTextInput label="Ismerd meg! URL" source={source} />
        </>
    );
};

const SiteAdSiteID = ({ record }) => {
    const site = SiteIDData[parseInt(record.site_id)];

    return <MyTextField source="site_id" data={site.name} />;

};
/*                    <FormDataConsumer>
        {({ formData, scopedFormData, getSource, ...rest }) => {
            const site = SiteIDData[parseInt(scopedFormData.site_id)];

            return (
                <MyTextField source={getSource('site_id')} data={site.name} />

            )
        }}
                    </FormDataConsumer>
 */
const SiteAdsForm = ({ permissions, record }) => {
    return (
                <ArrayInput source="siteads" label="" record={record} >
                    <SimpleFormIterator2 disableRemove disableAdd>
                        <SiteAdSiteID />
                        <ZeroOneBooleanInput label="Aktív?" source="status" record={record} />
        { permissions === 'admin' && <ZeroOneBooleanInput label="Elsődleges?" source="is_primary" record={record} /> }
                       <ZeroOneBooleanInput label="Promóció?" source="is_promo" record={record} />
                       <ZeroOneBooleanInput label="Főoldalon?" source="is_frontpage" record={record} />
                    </SimpleFormIterator2>
                </ArrayInput>
    );

};

const SiteAdsFormExtra = ({ permissions, record }) => {
    var myClickType = record.click_type;
    return (
                <ArrayInput source="siteads" label="" record={record} className="longtext-width">
                    <SimpleFormIterator disableRemove disableAdd>
                    <SiteAdSiteID />
 { myClickType === 0 && <SiteAdHref source="href" />}
 { myClickType === 0 && <SiteAdHrefCall source="href_call" />}
 { myClickType === 0 && <SiteAdHrefList source="href_list" />}
                    </SimpleFormIterator>
                </ArrayInput>
    );

};
//                                    <NumberFormat customInput={NumberInput} source="max_income" thousandSeparator={true} />

export const AdEdit = ({ permissions, ...props }) => (
    <Edit aside={<Aside />} undoable={false} {...props} title={<AdTitle />} >
        <TabbedForm redirect="show" submitOnEnter={false} >
            <FormTab label="Alapadatok">
                <SiteadsBanner />
                <TextField label="Azonosító" source="id" />
                <TextInput label="Termék neve" source="title" />
    {permissions === 'admin' && <TextInput label="Beküldési név, amennyiben külünbözik a termék nevétől (pl. Sberbank)" source="crm_name" className="label-top" />}
        {permissions === 'admin' ? <MyUser admin={true} /> : <MyUser />}
        {permissions === 'admin' ? <MyStatus admin={true} /> : <MyStatus /> }
        {permissions === 'admin' ? <MyClickType admin={true} /> : <MyClickType /> }
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.click_type && permissions === 'admin' && <TargetInput formData={formData.click_type} {...rest} /> }
                </FormDataConsumer>
                <SelectInput label="Minősített fogyasztóbarát hitel?" source="logo_display" choices={[
                    { id: 0, name: "Nem" },
                    { id: 1, name: "Igen" },
                ]} optionText="name" optionValue="id" />
                <DateField label="Létrehozás dátuma" source="time" />
            </FormTab>
            <FormTab label="Jellemzők">
                <ReferenceArrayInput label="Kategóriák" source="categories" reference="categories_list" perPage={100} validate={ required() } >
                     <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
    <TextInput label="Hirdetmény URL" source="proclamation_url" />
    <TextInput label="Hirdetmény Szöveg" source="proclamation_text" />
    <LongTextInput source="precheck_1" label="Termék Előszűrés 1" />
    <LongTextInput source="precheck_2" label="Termék Előszűrés 2" />
    <LongTextInput source="precheck_3" label="Termék Előszűrés 3" />
   <FormDataConsumer>
    {({formData, ...rest}) => {
        const dataLabels = findLabels(formData.categories);

        var label3 = dataLabels.label3 || "";
        var kieg = "Kiegészítő adat";
        var kieg3 = dataLabels.label3 ? "Kiegészítő adat" : "";

        return (
            <>
                <div style={styles.divTable}>
                    <div style={styles.divRow}>
                        <div style={styles.divCell}>
                            { dataLabels.label1 && <TextInput source="data_1" label={dataLabels.label1} validate={ required() } /> }
                        </div>
                        <div style={styles.divCell}>
                            { kieg && <TextInput source="data_2" label={kieg} /> }
                        </div>
                    </div>
                    <div style={styles.divRow}>
                        <div style={styles.divCell}>
                            { dataLabels.label2 && <TextInput source="data_3" label={dataLabels.label2} /> }
                        </div>
                        <div style={styles.divCell}>
                            { kieg && <TextInput source="data_4" label={kieg} /> }
                        </div>
                    </div>
                    <div style={styles.divRow}>
                        <div style={styles.divCell}>
                            { label3 && <TextInput source="data_5" label={label3} /> }
                        </div>
                        <div style={styles.divCell}>
                            { kieg3 && <TextInput source="data_6" label={kieg3} /> }
                        </div>
                    </div>
                </div>
            </>
        )
    }}
    </FormDataConsumer>
                <LongTextInput source="spec_1" label="Termék Jellemzők 1" />
                <LongTextInput source="spec_2" label="Termék Jellemzők 2" />
                <LongTextInput source="spec_3" label="Termék Jellemzők 3" />
                <LongTextInput source="spec_4" label="Termék Jellemzők 4" />
                <LongTextInput source="spec_5" label="Termék Jellemzők 5" />
            </FormTab>
            <FormTab label="Jogi és Igénylési feltételek">
                <RichTextInput label="Jogi Feltételek"  source="description" toolbar={[
                  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                  ['blockquote', 'code-block'],

                  ['link', 'image'],
                  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                  [{ 'direction': 'rtl' }],                         // text direction

                  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                  [{ 'font': [] }],
                  [{ 'align': [] }],

                  ['clean']                                         // remove formatting button
                ]} />
                <hr className={'jogi-feltetelek-szeparator'}  />
                <RichTextInput label="Igénylési Feltételek"  source="conditions" toolbar={[
                  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                  ['blockquote', 'code-block'],

                  ['link', 'image'],
                  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                  [{ 'direction': 'rtl' }],                         // text direction

                  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                  [{ 'font': [] }],
                  [{ 'align': [] }],

                  ['clean']                                         // remove formatting button
                ]} />
                <TextInput label="Kiemelt kép (URL)" source="image" />
            </FormTab>
            <FormTab label="Időzítés">
                <DateInput className="label-top" source="start_time" label="Időzített indulás (csak ÚJ és INAKTÍV termék esetén)" allowEmpty={true} />
                <DateInput className="label-top" source="end_time" label="Időzített leállítás (csak AKTÍV, futó termék esetén)" allowEmpty={true} />
                <DateInput className="label-top" source="effective_date" label="Termékjellemzők avagy kalkulátor adatok időzített élesítése, csak AKTÍV, futó termék esetén" allowEmpty={true} />
            </FormTab>
            <FormTab label="Oldalak">
                <SiteAdsForm permissions={permissions} />
                <SiteAdsFormExtra permissions={permissions} />
            </FormTab>
            { permissions === 'admin' && <FormTab label="Kalkulátor">
            <ReferenceArrayInput label="Címkék" source="tags" reference="tags" perPage={100} >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <FormDataConsumer>
            {({formData, ...rest}) => {

                const baseData = findDataFields(formData.categories);



                const renderCells = cells => cells.map(({ editComponent: Component, props }) => 
                    ( props.tooltip ?
                        <div class="tooltip">
                            <span class="tooltiptext">{props.tooltip}</span>
                            <div style={styles.divCell}>
                                <Component {...props} className="label-top" />
                            </div>
                        </div>
                        :
                        <div style={styles.divCell}>
                            <Component {...props} className="label-top" />
                        </div> )
                    );
                const renderRows = rows => rows.map(row => <div style={styles.divRow}>{renderCells(row)}</div>);
                return <div style={styles.divTable}> {renderRows(baseData)} </div>;

            }}
            </FormDataConsumer>
            <FormDataConsumer>
            {({formData, ...rest}) => {

                const baseRates = findRatesFields(formData.categories);
                const renderCells = cells => cells.map(({ editComponent: Component, props }) => <Component {...props} className="label-top" />);
                return (
                    <ArrayInput record={props.record} source="rates" label="Sávok" >
                    <SimpleFormIterator2>
                        {renderCells(baseRates)}
                    </SimpleFormIterator2>
                </ArrayInput>
                )
            }}
            </FormDataConsumer>
            </FormTab> }
        </TabbedForm>
   </Edit>
);
