import React from 'react';
import {
    List,
    Filter,
    Datagrid,
    TextField,
    TextInput,
    Pagination,
    SelectInput,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';


const ClickPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const ClickFilter = props => (
    <Filter {...props}>
        <SelectInput label="Intervallum" source="interval" choices={[
                   { id: 'week', name: 'Heti' },
                   { id: 'month', name: 'Havi' },
                   { id: 'current_month', name: 'Aktuális hónap' },
                   { id: 'previous_month', name: 'Előző hónap'},
                   { id: 'sum_cur_month', name: 'Összesített adott hónap'},
                   { id: 'sum_prev_month', name: 'Összesített előző hónap'},
            ]} alwaysOn allowEmpty={false} />
        <TextInput label="Termék keresése" source="id" alwaysOn />
    </Filter>
);

const exporter = clicks_report => {
    const clicksForExport = clicks_report.map(clicks => {
        const { ad, date, title, total, ...clicksForExport} = clicks;

        clicksForExport.date = clicks.date;
        clicksForExport.ad = clicks.ad;
        clicksForExport.title = clicks.title;
        clicksForExport.total = clicks.total;

        return clicksForExport;
    });
    const csv = convertToCSV({
        data: clicksForExport,
        fields: ['date', 'ad', 'title', 'total']
    });
    downloadCSV(csv, 'CTStatisztika', 'text/csv;charset=utf-8');
}

export const ClickList = props => (
    <List {...props} filters={<ClickFilter />} filterDefaultValues={{ interval: "week", id: "492" }} exporter={exporter} bulkActions={false} pagination={<ClickPagination />} title="CT Statisztika" >
        <Datagrid>
            <TextField label="ID" source="ad" />
            <TextField label="Termék neve" source="title" />
            <TextField label="Dátum" source="date" />
            <TextField label="Totál" source="total" />
        </Datagrid>
    </List>
);